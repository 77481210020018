<template>
  <div
    class="relative w-full h-full flex px-4 py-8 lg:p-10 justify-center items-center"
  >
    <div class="w-full max-w-3xl">
      <Logo />
      <div class="w-full my-8" style="padding:56.25% 0 0 0;position:relative;">
        <iframe
          src="https://player.vimeo.com/video/702768394?h=93126e9dff"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position:absolute;top:0;left:0;width:100%;height:100%;"
        ></iframe>
      </div>
      <CompanyLogo />
    </div>
  </div>
</template>

<route>
{
  "meta": {
    "auth": false
  }
}
</route>

<script>
import Logo from '../components/Logo';
import CompanyLogo from '../components/CompanyLogo';
export default {
  name: 'Index',
  layout: 'intro',
  components: { Logo, CompanyLogo }
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.intro {
  width: 460px;
  height: auto;
  max-width: 100%;
  margin-top: 40px;
}
</style>
